@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
  box-sizing: inherit;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

/* Initial fade when page is refreshed */

.init-fade {
  /* Reveal Item one by one */
  -webkit-animation: fadeIn 0.6s linear;
  animation: fadeIn 0.6s linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.logo-style:nth-child(1) {
  -webkit-animation-delay: 0.26s;
  animation-delay: 0.26s;
}

li:nth-child(1) {
  -webkit-animation-delay: 0.56s;
  animation-delay: 0.56s;
}

li:nth-child(2) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

li:nth-child(3) {
  -webkit-animation-delay: 0.96s;
  animation-delay: 0.96s;
}

li:nth-child(4) {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

h1:nth-child(1) {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

h2:nth-child(1) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.45s;
}

h3:nth-child(1) {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

p:nth-child(1) {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

a:nth-child(1) {
  -webkit-animation-delay: 2.05s;
  animation-delay: 2.05s;
}

a:nth-child(2) {
  -webkit-animation-delay: 10s;
  animation-delay: 5s;
}

/* a:nth-child(3) {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.45s;
}

a:nth-child(4) {
  -webkit-animation-delay: 2.55s;
  animation-delay: 2.55s;
}  */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 2;
  }
}

/* Reveal Section*/

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* @keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.reveal-fade-up {
  opacity: 0;
  animation: fadeUp 1s ease-in-out forwards;
} */

/* Body */

body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  scrollbar-color: black #e0e0e0;
  -webkit-font-smoothing: antialiased;
  font-family: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
  background-color: #0a192f;
  color: #8892b0;
  line-height: 1.3;
  font-size: 20px;
}

/* Scrollbar Styling */
html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

html::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #8892b0;
  background-color: #8892b0;
}

.root {
  height: 100%;
}

.layout-style {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main.fillHeight {
  padding: 0px 0px;
}

main {
  margin: 0px auto;
  width: 100%;
  max-width: 1600px;
  min-height: 100vh;
  padding: 200px 150px;
  display: block;
}

/* .side-bar {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: 20px;
  right: auto;
  z-index: 10;
  color: #b0bec5;
}

.side-bar ul {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.side-bar ul li a {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  padding: 10px;
  transition: var(--transition);
}

.side-bar ul::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: #b0bec5;
} */

h1,
h2,
h3,
h4 {
  color: #ccd6f6;
  font-weight: 700;
  line-height: 1.1;
}

.first-small-header h1 {
  margin: 0px 0px 20px 4px;
  color: #64ffda;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  font-size: clamp(14px, 5vw, 16px);
  /* font-size: 19px; */
  font-weight: 400;
}

.big-heading {
  margin: 0px;
  font-size: clamp(40px, 8vw, 80px);
}

.sub-header h3 {
  margin-top: 15px;
  color: #8892b0;
  line-height: 0.9;
  font-size: 55px;
}

.header-p p {
  margin: 20px 0px 0px;
  max-width: 540px;
}

.header-p b {
  color: #64ffda;
}

.home-buttons {
  color: #64ffda;
  background-color: transparent;
  border: 1px solid #64ffda;
  border-radius: 4px;
  padding: 1.25rem 1.75rem;
  font-size: 14px;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  /* transition: var(--transition);
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1) */
  margin-top: 50px;
  margin-right: 25px;
}

.home-buttons:hover {
  background-color: rgba(100, 255, 218, 0.4);
}

section {
  margin: 0px auto;
  padding: 100px 0px;
  max-width: 1000px;
  display: block;
}

.first-section {
  /* display: block; */
  -webkit-box-pack: center;
  flex-direction: column;
  align-items: flex-start;
  /* min-height: 100vh; */
  height: 100vh;
  padding: 0px;
  padding-top: 5%;
}

.first-section p {
  margin: 40px 0px 0px;
  max-width: 540px;
  font-size: 17px;
  line-height: 1.5;
}

/* Nav Bar Styling */

.header-style {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0px;
  z-index: 11;
  padding: 0px 50px;
  width: 100%;
  height: 100px;
  background-color: rgba(10, 25, 47, 0.85);
  /* backdrop-filter: blur(10px);
    transition: var(--transition); */
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  box-sizing: border-box;
}

.navbar-style {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  color: #ccd6f6;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  /* counter-reset: item 0; */
  z-index: 12;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.nav-link-style {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.nav-link-style ol {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.nav-link-style ol li {
  margin: 0px 5px;
  position: relative;
  /* counter-increment: item 1; */
  font-size: 13px;
}

.nav-link-style ol li a {
  padding: 10px;
}

a {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  /* transition: var(--transition); */
}

.nav-link-style a::after {
  display: block;
  content: "";
  border-bottom: solid 2px #64ffda;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.nav-link-style a:hover::after {
  transform: scaleX(1);
}

.logo-style {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.logo-style a {
  height: 60px;
  width: 60px;
}

.logo-style img {
  height: 60px;
  width: 60px;
}

/* Sidebar */

/* Page Headings */

::before,
::after {
  box-sizing: inherit;
}

.page-headings {
  overflow: hidden;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  position: relative;
  /* margin: 10px 0px 40px; */
  width: 100%;
  /* font-size: clamp(26px,5vw,32px); */
  font-size: 26px;
  /* white-space: nowrap; */
}

.page-headings:before,
.page-headings:after {
  background-color: #233554;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 10%;
}
.page-headings:before {
  right: 0.8em;
  margin-left: -50%;
}
.page-headings:after {
  left: 0.8em;
  margin-right: -50%;
}

/*** About Me ***/
.aboutme-section .inner {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
}

.aboutme-section .inner .inner-left p {
  font-size: 17px;
  margin: 0px 0px 15px;
  text-align: center;
}

.aboutme-section .inner .inner-right {
  position: relative;
  max-width: 300px;
}

.aboutme-section .inner .inner-right .wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 90%;
  border-radius: 6px;
}

.aboutme-section .inner .inner-right .wrapper img {
  max-width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .list-skills {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  gap: 0px 10px;
  padding: 0px;
  margin: 20px 0px 0px;
  overflow: hidden;
  list-style: none;
}

.list-skills li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  font-size: 16px;
}

/* Bullet-in Arrows for skills list */

.icon a,
img {
  transition: 1s ease;
}

.icon img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}

.icon a:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}

.tooltip {
  text-decoration: none;
  position: relative;
}

.tooltip span {
  display: none;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  color: black;
  background: white;
}

.tooltip:hover span {
  display: block;
  position: absolute;
  top: 0;
  left: -10px;
  width: auto;
  max-width: 100px;
  border: 1px solid black;
  margin-top: 50px;
  overflow: hidden;
  padding: 8px;
}
.tooltip span h2 {
  font-size: 15px;
}

/*** Experience ***/

/* Bouncing Animation for Containers */
@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.grid-wrapper {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 17.4rem));
  justify-content: center;
  grid-gap: 2rem;
  margin: 2rem;
}

/* Profile Card Container */
.grid-wrapper > .container {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px 0px #b0bec5;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  align-items: center;
  overflow: hidden;
  padding: 0;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 1rem 2rem;
  /* background-color: #faf9f6; */
  background-color: white;
}

.banner-img {
  position: absolute;
  background: #81e7f4;
  /* background: -webkit-linear-gradient(top left, #81E7F4, #3969BF); */
  /* background: -moz-linear-gradient(top left, #81E7F4, #3969BF); */
  /* background: linear-gradient(to bottom right, #64ffda, #ccd6f6); */

  background: -webkit-linear-gradient(top left, #8892b0, #ccd6f6);
  background: linear-gradient(to bottom right, #ccd6f6, #8892b0);
  height: 10rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Outline for Company Logo Circle */
.company-img-wrapper {
  background-color: #0a192f;
  clip-path: circle(55px at center);
  width: 8rem;
  height: 8rem;
  margin-top: 4.5rem;
}

/* Wrapper for Tutor Card */
/* .tutor {
    background-color: white;
} */

/*  Image for Tutor Card */
/* .tutor img {
  width: 8rem;
  clip-path: circle(55px at center);
  height: 8rem;
  background-color: white;
} */

.company-img {
  width: 8rem;
  clip-path: circle(55px at center);
  height: 8rem;
  background-color: white;
}

.company-name {
  font-size: 1.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 350;
  color: #0a192f;
  font-family: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
  margin-bottom: 15px;
}

.container h4 {
  color: #a8b2d1;
  font-size: 0.9rem;
  font-family: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

.grid-wrapper .description {
  margin: 1rem 1rem 2rem;
  font-size: 0.85rem;
  /* font-weight:  */
  color: #8892b0;
}

/*** Projects ***/

@media only screen and (min-width: 500px) {
  .grid-container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(3, 1fr);
  }
  div > div {
    background-color: #0a192f;
  }
}

.grid-item {
  position: relative;
  height: 200px;
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-item .img_contain {
  object-fit: contain;
  background-color: white;
}

.grid-item .grid-item-bio {
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  font-family: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
  opacity: 0;
  transition: opacity 0.6s;
}

.grid-item:hover .grid-item-bio {
  opacity: 1;
}

.grid-item:hover img {
  opacity: 0.2;
  transition: opacity 0.3s;
}

.grid-item-bio-title {
  /* display: block;
    position: absolute; */
  color: #ccd6f6;
  background-color: transparent;
  margin-top: 40px;
  margin-bottom: 2px;
  font-weight: 700;
}

.grid-item-bio-desc {
  color: #8892b0;
  background-color: transparent;
  margin-bottom: 50px;
  font-size: 13px;
  font-weight: 400;
}

.grid-item-bio-lan {
  color: #64ffda;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  font-size: 13px;
  background-color: transparent;
}

/*** Contact ***/

.contact-section {
  /* max-width: 600px; */
  text-align: center;
  margin-bottom: 10rem;
}

.contact-section p {
  margin-top: 10px;
  font-size: 18px;
}

.contact-section .home-buttons {
  margin-right: 0;
}

/*** Footer ***/

.footer-style {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-height: 20px;
  padding: 10px;
  text-align: center;
  background-color: #b0bec5;
}

.credits {
  color: #0a192f;
  font-family: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  /* padding-left: 60px; */
}

.credits a {
  padding: 10px;
}

/* .hidden {
  opacity: 0;
  transition: all 1s;
  transform: translateX(-100%);
}

.show {
  opacity: 1;
  transform: translateX(0);
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
} */

/* mobile responsive */
@media screen and (max-width: 960px) {
  #content {
    padding: 0 2.5rem;
  }

  .header-style {
    padding: 1.6rem;
  }
  .nav-link-style {
    display: none;
  }

  .sidebar {
    display: none;
  }

  .first-section {
    /* padding: 10rem 0rem; */
  }

  /* .first-small-header,
  .main-header,
  .sub-header,
  .header-p {
    padding: 0 2.5rem;
  } */

  .first-small-header h1,
  .main-header h2,
  .sub-header h3,
  .header-p p {
    margin: 20px 0;
  }

  .first-small-header h1 {
    font-size: 15px;
  }
  .main-header h2 {
    font-size: 40px;
  }

  .sub-header h3 {
    font-size: 19px;
  }

  .header-p p {
    font-size: 12px;
  }

  .home-button-wrapper {
    /* padding: 0 2.5rem; */
    margin: 20px 0;
  }

  .home-buttons {
    margin: 0;
    padding: 1.1rem 1.4rem;
    font-size: 12px;
  }

  .aboutme-section .inner {
    grid-template-columns: 1fr;
  }

  .aboutme-section .inner .inner-left p {
    font-size: 13px;
  }
  .aboutme-section .inner .inner-right {
    max-width: 100%;
  }

  .aboutme-section .inner .inner-right .wrapper {
    justify-content: center;
    align-items: center;
  }

  .aboutme-section .inner .inner-right .wrapper img {
    height: 300px;
  }

  .icon-list {
    width: 100%;
  }

  /* .aboutme-section .inner .inner-right {
    display: none;
  } */

  .experience-section .grid-wrapper {
    margin: 0;
  }

  .projects .grid-container .grid-item {
    margin: 0.5rem 0;
  }

  .contact-section p {
    margin-bottom: 3rem;
  }

  .footer-style {
    font-size: 10px;
  }
}
